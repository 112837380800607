/* Basic styles for scrollbar */
.scroll-sidebar {
  height: 100%; /* Ensure the sidebar has a defined height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling, if not needed */
  padding-right: 6px; /* Adjust for scrollbar width to prevent content from shifting */
}

/* Optional: Customize scrollbar appearance (for Webkit browsers) */
.scroll-sidebar::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.scroll-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Scrollbar color */
  border-radius: 8px; /* Rounded corners */
}

.scroll-sidebar::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

/* Collapsible menu styles */
.collapse {
  display: none; /* Hide by default */
  overflow: hidden; /* Hide overflow to avoid scrollbars */
  transition: max-height 0.3s ease-out; /* Smooth transition */
}

.collapse.show {
  display: block; /* Show when active */
  max-height: 500px; /* Adjust based on content size */
}

.sidebar-link.has-arrow {
  cursor: pointer; /* Change cursor to pointer for clickable items */
}

/* Optional: Add styling for active dropdowns */
.sidebar-link[aria-expanded="true"] {
  font-weight: bold; /* Example style for active link */
}



.sidebar-nav #sidebarnav .sidebar-item.selected > .sidebar-link {
  border-radius: 0px 60px 60px 0px;
  color: #fff !important;
  background: linear-gradient(to right, #8971ea, #7f72ea, #7574ea, #6a75e9, #5f76e8);
  box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
  opacity: 1;
}
