.border-start {
    border-left: 1px solid #75797e; /* Adjust color as needed */
  }
  
  .border-end {
    border-right: 1px solid #75797e; /* Adjust color as needed */
  }
  
  .flex-fill {
    padding: 0 15px; /* Add padding for spacing */
  }
  

  .icon-wrapper {
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center;     /* Center the icon vertically */
    /* margin-bottom: 5px;      Space between icon and text */
   
  }
  

  .align-items-center {
    align-items: center; /* Center items vertically */
  }
  
  .me-2 {
    margin-right: 0.5rem; /* Adjust spacing between icon and text */
  }
  