/* Set white background, z-index, box-shadow, black border, and no border radius for the wrapper */

/* .rmdp-wrapper {
  background-color: white !important; 
  z-index: 100; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important; 
  border: 1px solid black !important; 
  border-radius: 0 !important; 
} */

/* f8f9fa */


.rmdp-calendar {
  background-color: #5f76e8 !important;
  border: black !important; 
  border-radius: 0 !important; 
}

.rmdp-header {
  background-color: #5f76e8  !important;
}

.rmdp-day {
  background-color: white !important; 
  border: none !important; 
  border-radius: 0 !important; 
}

.rmdp-day.rmdp-today.rmdp-selected {
  background-color: #5f76e8  !important;
  color: white !important;
}

.rmdp-shadow {
  box-shadow: 0 0 0px;
}

.rmdp-day:hover {
  background-color: #5f76e8 !important; 
  color: #333 !important;
}

.rmdp-input {
  border: 1px solid #000  !important; 
  border-radius: 0 !important; 
  font-size: 14px;
  padding: .6rem 1rem;
  width: 100%;
  height: auto;
}

.rmdp-container {
  display: block;
}

.myContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1 0px;
}

.myDatePicker {
  width: 100%;
}


.rmdp-week-day {
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
}

.rmdp-arrow-container:hover {
  background-color: #fff ;
  box-shadow: 0 0 3px #8798ad;
}

.rmdp-arrow-container {
  background: #5f76e8 ;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0 5px;
  padding: 0;
  width: 20px;
}

.rmdp-arrow {
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 2px;
  width: 3px;
}