.center-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.center-buttons .separator {
  margin: 0 10px;
  font-size: 20px;
  color: #000;
}

.btn-primarys {
  background-color: #5f76e8;
  border: none;
  color: white;
  width: 130px;
  height: 40px;
  font-size: 15px;
  border-radius: 20px;
}

.btn-red {
  background-color: #c73f3a;
  border: none;
  color: white;
  width: 130px;
  height: 40px;
  font-size: 15px;
  border-radius: 20px;
}

.btn-outline-primarys {
  background-color: transparent;
  border: 1px solid #5f76e8;
  color: #5f76e8;
}

.btn-primarys:hover {
  background-color: #0056b3;
}

.btn-outline-primarys:hover {
  background-color: #007bff;
  color: white;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn_disabled {
  background-color: #dcdcdc;
  border-color: #dcdcdc;
  color: #000;
  /* padding: 0 20px;  */
  width: 160px;
  height: 40px;
  font-size: 15px;
  cursor: not-allowed;
}

.btn_disabled:disabled {
  opacity: 0.6;
}

.btn:disablednext {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn_disabledNext {
  background-color: #dcdcdc;
  border-color: #dcdcdc;
  color: #888;
  padding: 10px 20px;
  border-radius: 0;
  cursor: not-allowed;
}

.btn_disabledNext:disabled {
  opacity: 0.6;
}

.btn_green {
  background-color: #65cd50;
  padding: 10px 20px;
  border-radius: 0;
}

.btn_black_text {
  color: black;
}


.profile-image-spouse-consumer{
  width: 150px;
  height: 150px;
}


/* .form-control {
  display: block;
  width: 180px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4F5467;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  border: 1px solid black;
  transition: border-color 0.15sease-in-out, box-shadow 0.15sease-in-out;
}

.custom-col {
  max-width: 25% !important;
  flex: 0 0 25% !important;
} */
